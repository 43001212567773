import { Component, createRef, lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import validator from "validator"; 

// ~ Modules ~ \\
import { withRouter } from "@modules/scripts/withRouter";
import { sendMail } from "@modules/scripts/ajax";
import loadModule from "@modules/scripts/loadModule";
import Q from "@modules/query/query";
import PopUps from "@modules/popup";


// ~ Assets ~ \\
import Logo from "@public/img/bp-garant.png";
import LogoInvest from "@public/img/bp-invest-biela.png";
import './contract.scoped.scss'
import './contract.scss'


// ~ Zmluvy ~ \\
const Zm1 = lazy(() => import("./contracts/zm1")),
      Zm2 = lazy(() => import("./contracts/zm2")),
      ZmCZ = lazy(() => import("./contracts/zmCZ")),
      ZmEN = lazy(() => import("./contracts/zmEN")),
      Zm3 = lazy(() => import("./contracts/zm3")),
      Completed = lazy(() => import("./completed"));

export const pages = [
    {
        name:"Zmluva o pôžičke",
        El:Zm1,
        color:"#ceaa52",
        buttonText:"Overiť sa",
        db:"pozicka",
        file:["pozicka","vyhlasenie"],
        logo:Logo,
        redirect:"/zmluva-garant-sq-4-8,5-2"
    },
    {
        name:"Zmluva o upísaní dlhopisu",   
        desc:"BIZPARTNER GARANT  1 / 8,5 / 2",
        El:Zm2,
        color:"#0098da",
        path:"zmluva-garant",
        buttonText:"Overiť sa",
        db:"dlhopis",
        US:1,
        text:{
            spol:"BizPartner Investments, s. r. o.",
            RC:"SK4000024147"
        },
        docFiles:["BIZPARTNER_GARANT_EMISNE_PODMIENKY.pdf"],
        file:["dlhopis"],
        logo:Logo,
    },
    {
        name:"Zmluva o upísaní dlhopisu bonus",   
        desc:"BIZPARTNER GARANT  1 / 8,5 / 2",
        El:Zm3,
        path:"zmluva-garant-bonus",
        color:"#0098da",
        buttonText:"Overiť sa",
        db:"dlhopisBonus",
        file:["dlhopisBonus","bonus"],
        logo:Logo,
    },
    {
        name:"Zmluva o upísaní dlhopisu",   
        desc:"BIZPARTNER GARANT  2 / 9,3 / 2",
        El:Zm2,
        path:"zmluva-garant-2-9,3-2",
        color:"#ceaa52",
        buttonText:"Overiť sa",
        db:"dlhopisZML",
        US:0,
        text:{
            spol:"BizPartner Investments 2, s. r. o.",
            RC:"SK4000025078"
        },
        docFiles:["EMISNE_PODMIENKY_-_BizPartner_Garant2_9,3_2.pdf"],
        file:["dlhopisZML"],
        logo:LogoInvest,
        personId: "a5cf11d5-6308-452c-b3d8-d9ee1ebd4742"
    },
    {
        name:"Zmluva o upísaní dlhopisu",   
        desc:"BIZPARTNER GARANT  3 / 9,3 / 2",
        El:Zm2,
        path:"zmluva-garant-3-9,3-2",
        color:"#ceaa52",
        buttonText:"Overiť sa",
        db:"dlhopisZML3",
        US:0,
        dates:{startDate:"2026-05-01",endDate:"2026-04-30"},
        text:{
            spol:"BizPartner Investments 3, s. r. o.",
            RC:"SK4000025409"
        },
        docFiles:["EMISNE_PODMIENKY_-_BizPartner_Garant_3_9,3_2.pdf"],
        file:["dlhopisZML3"],
        logo:LogoInvest,
        personId:"74fb09fa-8d9f-4eb9-b9cf-25245628794e"
    },
    {
        name:"Zmluva o upísaní dlhopisu",   
        desc:"BIZPARTNER GARANT  SQ / 9,1 / 2",
        El:Zm2,
        path:"zmluva-garant-sq-9,1-2",
        color:"#ceaa52",
        buttonText:"Overiť sa",
        db:"dlhopisZML4",
        US:2,
        dates:{startDate:"2026-05-22",endDate:"2026-07-22"},
        text:{
            spol:"BizPartner Garant SQ, j. s. a",
            RC:"SK4000025722"
        },
        docFiles:["EMISNE_PODMIENKY_-_BizPartner_Garant_SQL_9,1_2.pdf"],
        file:["dlhopisZML4"],
        logo:LogoInvest,
    },
    {
        name:"Zmluva o upísaní dlhopisu",   
        desc:"BIZPARTNER GARANT  4 / 8,5 / 2",
        El:Zm2,
        path:"zmluva-garant-sq-4-8,5-2",
        color:"#ceaa52",
        buttonText:"Overiť sa",
        db:"dlhopisZML5",
        US:1,
        dates:{startDate:"2024-07-10",endDate:"2026-07-10"},
        text:{
            spol:"BizPartner Investments 4, j. s. a.",
            RC:"SK4000026084"
        },
        docFiles:["EMISNE_PODMIENKY_-_BizPartner_Garant_4_8,5_2.pdf"],
        file:["dlhopisZML5"],
        logo:LogoInvest,
        personId:"f3ed3043-3168-457e-9d8d-bf400100787d"
    },
    {
        name:"Zmluva o upísaní dlhopisu",   
        desc:"BIZPARTNER GARANT LIMITED",
        El:Zm2,
        path:"zmluva-garant-limited",
        color:"#ceaa52",
        buttonText:"Overiť sa",
        db:"dlhopisZMLLimited",
        US:2,
        dates:{startDate:"2024-07-28",endDate:"2026-07-28"},
        text:{
            spol:"BizPartner Garant Limited, s.r.o.",
            RC:"SK4000026217"
        },
        docFiles:["EMISNE_PODMIENKY_-_BizPartner_Garant_Limited.pdf"],
        file:["dlhopisZMLLimited"],
        logo:LogoInvest,
        personId:"7a918917-4a25-4d3a-be1f-3d27d9238296"
    },
    {
        name:"Smlouva o upsání dluhopisu",   
        desc:"BIZPARTNER GARANT EU 1 / 9,1 / 2",
        El:ZmCZ,
        path:"zmluva-garant-eu1912cz",
        color:"#ceaa52",
        buttonText:"Ověřit se",
        db:"dlhopisZMLEU1",
        US:2,
        locale:"cs_CZ",
        dates:{startDate:"2024-07-28",endDate:"2026-07-28"},
        text:{
            spol:"BizPartner Group CZ, s.r.o.",
            RC:"CZ0003567356"
        },
        docFiles:["Emisni_podminky_-_BizPartner_Garant_EU1-9,1-2.pdf"],
        file:["dlhopisZMLEU1CZ"],
        logo:LogoInvest,
        personId:"89fafe8a-8519-42d4-9d0e-99c2464feba7"
    },
    {
        name:"Bond subscription agreement",   
        desc:"BIZPARTNER GARANT EU 1 / 9,1 / 2",
        El:ZmEN,
        path:"zmluva-garant-eu1912en",
        color:"#ceaa52",
        buttonText:"Verify yourself",
        db:"dlhopisZMLEU1",
        US:2,
        locale:"en_GB",
        dates:{startDate:"2024-07-28",endDate:"2026-07-28"},
        text:{
            spol:"BizPartner Group CZ, s.r.o.",
            RC:"CZ0003567356"
        },
        docFiles:["Bond_Issue_Terms_and_Conditions_-_BizPartner_Garant_EU1-9,1-2.pdf"],
        file:["dlhopisZMLEU1EN"],
        logo:LogoInvest,
        personId:"89fafe8a-8519-42d4-9d0e-99c2464feba7"
    },
    {
        name:"Smlouva o upsání dluhopisu",   
        desc:"BizPartner Garant EU 2 / 9,1 / 2",
        El:ZmCZ,
        path:"zmluva-garant-eu2912cz",
        color:"#ceaa52",
        buttonText:"Ověřit se",
        db:"dlhopisZMLEU2",
        US:2,
        locale:"cs_CZ",
        dates:{startDate:"2025-02-24",endDate:"2027-02-24"},
        text:{
            spol:"BizPartner Garant 1 s.r.o.",
            RC:"CZ0003570210"
        },
        docFiles:["Emisni_podminky_-_BizPartner_Garant_EU2-9,1-2.pdf"],
        file:["dlhopisZMLEU2CZ"],
        logo:LogoInvest,
        personId:"2683db6b-2626-41b3-ac74-3a1763c3cd47"
    },
]

class Contract extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep:0,
            allSteps:[],
            data:{},
            params:{},
            prevData:{},
            required:[],
            status:false,
            locale:{}
        }
        this.El = createRef();
        this.handleChange = this.handleChange.bind(this)
        this.required = this.required.bind(this)
        this.generatePDF = this.generatePDF.bind(this)
    }
    
    componentDidMount() {
        const {color,name} = this.props,
              Data = JSON.parse(sessionStorage.getItem("data"))

        if(color)
        {   
            window.Q("html").setCss(ife("--color",color))
        }
        if(name)
        {
            document.title = name+" - BizPartner Group"
            Q("meta[property='og:title']").addAttr("content", name+" - BizPartner Group")
        }
        else
        {
            document.title = "BizPartner Group"
        }
        
        this.setLang()
        
        if(Data!== null || Data !== undefined)
        {
            this.setState((prev)=>({
                data:{...prev["data"],...Data}
            }))
        }
        
    }
    async setLang()
    {
        const {locale} = this.props,
              defaultLang = await import("./locale/sk_SK.js").then((e)=>e.default);
        this.lang = await import("./locale/"+(locale??"sk_SK")+".js").then((e)=>e.default);
        loadModule(defaultLang,this.lang);
        this.setState((prev)=>({
            locale
        }))
    }

    submit(){
        if(!this.verify()) return;
        this.setState({status:true});
        const {data} = this.state,
              {db,file,name,color,personId,locale} = this.props;
        
        let d = this.editBeforePost(data,file);
        
        window.$ajax({
            method:"POST",
            file:"upload",
            body:JSON.stringify({what:db,save:true,data:{...d}})
        },(e)=>{
            if(e?.upload)
            {
                
                PopUps.addPopUp({
                    type:"info",
                    message:this.lang?.notifications.dataUploaded,
                    icon:"check"
                })
                
                window.$ajax({
                    method:"POST",
                    file:"vf",
                    body:JSON.stringify({ID:e.id,personId,locale,what:"create"})
                },(f)=>{
                        if(f.openCode)
                        {
                            sendMail({
                                to: d.mail,
                                subject: name,
                                headText:name,
                                color:color,
                                text:this.lang?.email.text,
                                infoEmail:this.lang?.email.infoEmail,
                                button:this.lang?.email.button,
                                buttonLink: "https://app.veriface.eu/?oc="+f.openCode+"&noThanks=true&redirectUri="+window.MainUrl+"completed"
                            })

                            sendMail({
                                to: window.infoMailTo,
                                addPeople:window.infoMailAdd,
                                subject: name+" č."+e.id,
                                headText:name,
                                text:"Bola vypísaná nová zmluva č."+e.id+" na meno "+d.name+" a čaká na overenie.",
                                infoEmail:"Tento email ste obdržali na základe vyplnenia formuláru zákaznikom na www.bizpartnergroup.com",
                                button:"Pozrieť zmluvy",
                                buttonLink: window.MainUrl+"login"
                            })
                            window.$ajax({
                                method:"POST",
                                file:"upload",
                                multiple:true,
                                body:JSON.stringify({what:db,save:false,data:{doc_id:e.id,verifaceID:f.sessionId}})
                            },(j)=>{
                                if(j.upload)
                                {
                                    this.setState({status:false});
                                    window.location.href = "https://app.veriface.eu/?oc="+f.openCode+"&noThanks=true&redirectUri="+window.MainUrl+"completed";
                                    //console.log("https://app.veriface.eu/?oc="+f.openCode+"&noThanks=true&redirectUri="+window.MainUrl+"completed");
                                }
                            },()=>{
                                this.setState({status:false});
                            })
                        }
        
                    },()=>{
                        throw TypeError('Data was not uploaded.');
                    })
                    
            }
            else
            {
                throw TypeError('Data was not uploaded.');
            }
        },()=>{ 
            this.setState({status:false});
            PopUps.addPopUp({
                id:9999,
                info:this.lang?.notifications.dataUploadError,
                container:'Right',
                icon:'info',
                replace:true,
                timer:5000,
                ownClass:'error_message'
            })
        })
        
    }

    editBeforePost(e,docfile){
        const {locale} = this.props
        let d = window.copyObj(e);
        delete d.gdpr;
        delete d.vop;
        delete d.date;
        d.locale = locale;
        d.docFile = docfile;
        
        Object.entries(d).forEach((e,i)=>{
            if(window.checkJSON(e[1]) && !Array.isArray(e[1]))
            {
                d[e[0]] = e[1].value
            }
        })
        d.vstupnypoplatok = 0;
        return d;

    }
    generatePDF(e){
        const //{data} = this.state,
              {db,file} = this.props

        this.setState({status:true});
        //let d = this.editBeforePost(data,e);

        
        fetch(window.MainApiUrl+'/zmluvy/zmluva.php',{
            method:"POST",
            mode:"cors",
            headers:{},
            body:JSON.stringify({what:db,data:{preview:true,docFile:file[e-1]}})
        })
        .then((e)=> e.arrayBuffer())
        .then(e=>{    
            this.setState({status:false});
            const blob = new Blob([e],{type: 'application/pdf'});
            window.open( URL.createObjectURL(blob) );
        }).catch((e)=>{
            this.setState({status:false});
        })

    }

    navigate(e)
    {
        this.setState((prev)=>({
            currentStep:prev.currentStep+e
        }))
        window.scrollElementIntoView(this.El.current,"start");
    }
    
    handleChange(i,e)
    {
        let { prevData, data } = this.state
        

        if(Object.entries(prevData).length <= 0)
        {
            prevData = window.copyObj(data);
        }
        if(window.checkJSON(i))
        {
            Object.entries(i).forEach((j,k)=>{
                data[j[0]] = j[1];
            })
            this.setState({
                data:data,
                prevData
            })
        }
        else
        {
            data[i] = e;
            this.setState({
                data:data,
                prevData
            })
        }

        // Save data to session storage \\
        let d = window.copyObj(data);
        delete d["gdpr"];
        delete d["vop"];
        sessionStorage.setItem("data",JSON.stringify(d));

    }
    required(e,r=false){
        const {required,currentStep:step} = this.state

        if(r && required.find(data => data.name === e))
        {
            this.setState((prev)=>({
                required:[...required.filter(key => key.name !== e)]
            }))
            return true;
        }
        else if(!r && !required.find(data=>data.name === e))
        {
            this.setState((prev)=>({
                required:[...required,{name:e,step}]
            }))
        }
    }
    verify()
    {
        let pass = true,
            object = null,
            objects = [],
            message = this.lang?.notifications.fillAll;
        const {required,currentStep,data} = this.state
        let phone = data.phone;
        required.map(e=>{
            if((e.step === currentStep && !(e.name in data)) || (e.step === currentStep && ((e.name in data) && data[e.name] === "")) )
            {
                object = e.name;
                pass = false;
                objects.push({name:e.name,message});
                return false;
            }
            else if(e.step === currentStep && (e.name in data) && data[e.name] === false )
            {
                message = this.lang?.notifications.checkAll
                object = e.name;
                pass = false;
                objects.push({name:e.name,message});
                return false;
            }
            else if( e.step === currentStep && (
                (e.name === "mail" && !validator.isEmail(data?.mail??"")) ||
                (e.name === "iban" && !validator.isIBAN(data?.iban??"")) ||
                (e.name === "phone" && !validator.isMobilePhone(phone.replace(' ', '')??""))
            ))
            {
                message = this.lang?.notifications.wrongData
                object = e.name;
                pass = false;
                objects.push({name:e.name,message});
                return false;
            }
            else if( e.step === currentStep && (
                (e.name === "reMail" && data?.mail !== data?.reMail) ||
                (e.name === "rePhone" && data?.phone !== data?.rePhone)
            ))
            {
                message = this.lang?.notifications.dataNotSame
                object = e.name;
                pass = false;
                objects.push({name:e.name,message});
                return false;
            }
            return true;
        })
        if(!pass)
        {
            if(objects.length > 1)
            {
                objects.forEach((e)=>{
                    Q('.input-'+e.name+" input").css('border:solid 1px red');
                    Q(".input-"+e.name).class("add","error");
                    if(e.name === "date"){
                        Q(".date_picker").css('border:solid 1px red')
                    }

                    setTimeout(()=>{
                        Q('.input-'+e.name+' input, .date_picker').css('');
                        Q(".input-"+e.name).class("remove","error");
                    },5000)

                })
                message = this.lang?.notifications.fillAll
                PopUps.addPopUp({
                    id:9999,
                    info:message,
                    container:'Right',
                    icon:'info',
                    replace:true,
                    timer:5000,
                    ownClass:'error_message'
                })
                return pass;
            }

            PopUps.addPopUp({
                id:9999,
                info:message,
                container:'Right',
                icon:'info',
                replace:true,
                timer:5000,
                ownClass:'error_message'
            })
            Q('.input-'+object+" input").css('border:solid 1px red');
            Q(".input-"+object).class("add","error");
            if(object === "date"){
                Q(".date_picker").css('border:solid 1px red')
            }
            setTimeout(()=>{
                Q('.input-'+object+' input, .date_picker').css('');
                Q(".input-"+object).class("remove","error");
            },5000)
        }

        return pass;
    }

    nextStep()
    {
        if(this.verify())
        {
            this.navigate(1);
        }
    }


    render() {
        const {final} = this.props,
        {currentStep,allSteps,data,required,status,page} = this.state,
        {El,logo,name,desc,buttonText,US,docFiles,text,redirect,dates} = page??this.props;
        return ( 
            <div className="contract" ref={this.El}>
                <div className="header">
                    <img height="50" alt="" src={logo??LogoInvest}/>
                    <h1>{name}</h1>
                    <p>{desc}</p>
                </div>
                {
                final?
                    <Completed  pages={pages} setParamPage={(e)=>this.setState({page:e})}/>
                :<>
                    <div className="progress">
                        <ul className="progressbar">
                            {allSteps.map((e,i)=>{
                                let active = false;
                                if(e.step-1<=currentStep)
                                {
                                    active = true
                                }
                                return <li key={i} className={active?"active":""}>{e.name}</li>
                            })}
                        </ul>
                    </div>
                    <form action="" method="post" noValidate={true}>
                    <Suspense fallback={<></>}>
                    {
                        El?
                        <>
                            <El allSteps={(e)=>this.setState({allSteps:e})} 
                                currentStep={currentStep}
                                data={data}
                                uroks={US}
                                docFiles={docFiles}
                                text={text}
                                dates={dates}
                                redirect={redirect}
                                reqData={required}
                                required={this.required}
                                handleChange={this.handleChange}
                                generatePDF={this.generatePDF}
                            />
                            <div className="navigation">
                                {
                                    currentStep > 0?
                                    <div className="button back" onClick={()=>this.navigate(-1)}><p>{this.lang?.buttons.back || "Späť"}</p></div>
                                    :<></>
                                }
                                {
                                    currentStep < allSteps.length-1?
                                    <div className="button" onClick={()=>this.nextStep()}><p>{this.lang?.buttons.next || "Ďalej"}</p></div>
                                    :<button className="button" type="button" onClick={(e)=>this.submit(e)} ><p>{buttonText??"Podpísať"}</p></button>
                                }
                            </div>
                        </>
                        :<Navigate to="/404"/>
                    }
                    </Suspense>
                        <div className={"status"+(status?" loading":"")} ></div>
                    </form>
                </>
                }

            </div>
        );
    }
}

export default withRouter(Contract);

function ife(name,val){
    if(val !== undefined || val!== null || val !== "")
    {
        return {[name]:val};
    }
}